body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin-top:20px;
  background: #f8f8f8;
}

.todo-list {
   margin: 10px 0
}

.todo-list .todo-item {
  padding: 15px;
  margin: 5px 0;
  border-radius: 0;
}

div.checker {
  width: 18px;
  height: 18px
}

div.checker input {
  width: 18px;
  height: 18px
}

div.checker {
  display: inline-block;
  vertical-align: middle;
}

.done {
  text-decoration: line-through;
}